import { ViteSSG } from 'vite-ssg';
import { setupLayouts } from 'virtual:generated-layouts';
import App from '~/App.vue';
import type { UserModule } from '~/modules/user-module-type';
import { useUserStore } from '~/stores/user-store';
import { setPsdcRedirect } from '~/utilities/psdc-redirect';
import { useBadgesStore } from '~/stores/badges-store';
import { useFavoritesStore } from '~/stores/favorites-store';
import { useCollectionsStore } from '~/stores/collections-store';
import { routes } from '~/router';

import './tailwind.css';
import 'floating-vue/dist/style.css';
import 'vue-final-modal/style.css';

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  {
    routes: setupLayouts(routes),
    base: import.meta.env.BASE_URL,

    scrollBehavior: (to, _from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else if (to.meta.layout === 'DocsLayout' && to.hash) {
        return {
          el: to.hash,
          behavior: 'instant',
          top: 36,
        };
      } else {
        return {
          top: 0,
        };
      }
    },
  },
  async (ctx) => {
    // Install all modules under `modules/`
    Object.values(
      import.meta.glob<{ install: UserModule }>('~/modules/*.ts', { eager: true }),
    ).forEach((i) => i.install?.(ctx));

    if (!import.meta.env.SSR) {
      const userStore = useUserStore();

      // On page load, fetch the user if not logged in.
      if (!userStore.user) {
        await userStore.fetchUser();
      }

      // On page load, fetch badges and favorites if logged in.
      if (userStore.isLoggedIn && userStore.user?.id) {
        const badgesStore = useBadgesStore();
        const favoritesStore = useFavoritesStore();
        const collectionsStore = useCollectionsStore();
        try {
          await badgesStore.fetchBadges(userStore.user.id);

          // Don't await here to prevent blocking the page load.
          collectionsStore.init();
          favoritesStore.init();
        } catch (error) {}
      }

      // Runs before every route change
      ctx.router.beforeEach(async (to) => {
        // For pages where the user needs auth prior to visiting the page.
        if (to?.meta?.requiresAuth && !userStore.isLoggedIn) {
          await setPsdcRedirect();
          return { name: 'home' };
        }
      });

      // Workaround to get access to the router instance
      window.routerInstance = ctx.router;
    }
  },
);
