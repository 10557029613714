import 'reflect-metadata'; // Shim for Metadata Reflection API. Required by the class-transformer module.
import { Expose, Type } from 'class-transformer';
import type { SnakeCaseKeys } from '~/utilities/snake-case';
import path from '~/utilities/path';
import type { ParsedPath } from '~/utilities/path.types';

/**
 * Makes a specific property optional.
 * For example: `MakeOptional<PostBodyCreateVersion, 'version`> will make the 'version' key optional.
 */
export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;
export type ProjectType = 'app' | 'plugin' | 'app/invent';
export type ProductName = 'pyscript_founder';

export interface PutBodyUpdateProjectMetadata {
  name?: string;
  description?: string;
  type?: ProjectType;
  default_version?: string;
  tags?: string[];
  api_proxies_allowed?: string[];
  auth_required?: boolean;
  auth_users_allowed?: string[];
}

export interface PostBodyForkProject {
  /** If no name is provided, back-end will generate it by appending "Copy". */
  name?: string;
  version?: string;
}

export interface PutBodyUpdateVersion {
  index_file?: string;
  published?: boolean;
}

export interface PostBodyCreateProject {
  name: string;
  description: string;
  type: ProjectType;
}

export interface AssistantPromptResponse {
  answer: string;
}

export class Project {
  api_proxies_allowed: string[];
  created_at: string;
  default_version: string;
  description: string;
  icon: string;
  id: string;
  name: string;
  slug: string;
  type: ProjectType;
  updated_at: string;
  user_id: string;
  username: string;
  tags: string[];
  auth_required: boolean;
  auth_users_allowed: string[];

  @Type(() => Version)
  latest: Version;
}

export class Version {
  blocked: boolean;
  created_at: string;
  id: string;
  index_file: string;
  project_id: string;
  published: boolean;
  updated_at: string;
  url: string;
  user_id: string;
  version: string;
  version_number: number;
  tags: string[];
}

export interface ProjectStats {
  viewed: number;
  forked: number;
  shared: number;
}

export interface ErrorResponse {
  detail: ErrorDetail[];
}

export interface ErrorDetail {
  loc: string[];
  msg: string;
  type: ProjectType;
}

export class FileMetadata {
  hash: string;
  path: string; // `{userId}/{projectId}/{version}/path/to/file.py`
  name: string; // file.py
  size: number; // File size in bytes
  updated_at: string; // datetime
  isGhostFile?: boolean; // Used to indicate a file is in the process of being uploaded
  type?: string; // mimetype of the file
  /**
   * Removes the `{userId}/{projectId}/{version}/` portion of the path
   */
  @Expose()
  get pathTruncated(): string {
    return this.path.split('/').slice(3).join('/');
  }

  // `{userId}/{projectId}/{version}/path/to/file.py`
  @Expose()
  get bucketPath(): string {
    return this.path;
  }

  @Expose()
  get parsedBucketPath(): ParsedPath {
    return path.parse(this.path);
  }

  @Expose()
  get parsedPathTruncated(): ParsedPath {
    return path.parse(this.pathTruncated);
  }

  // `abcd1234-abcd-abcd-abcd-abcdef123456`
  @Expose()
  get userId(): string {
    return this.path.split('/').at(0) as string;
  }

  // `abcd1234-abcd-abcd-abcd-abcdef123456`
  @Expose()
  get projectId(): string {
    return this.path.split('/').at(1) as string;
  }

  @Expose()
  get version(): string | 'latest' {
    return this.path.split('/').at(2) as string;
  }
}

export interface FilePathData {
  bucketPath: string; // `{userId}/{projectId}/{version}/path/to/file.py`
  path: string; // E.g. `path/to/file.py`
  parsedPath: ParsedPath;
  userId: string;
  projectId: string;
  version: string | 'latest';
}

export interface Quotas {
  'account-storage-space-quota'?: { max: number };
  'api-proxies-quota'?: { max: number };
  'file-size-quota'?: { max: number };
  'private-projects-quota'?: { max: number };
}

export interface Whoami {
  id: string;
  email: string;
  username: string | null;
  name: string;
  avatar: string;
  tier_id: string;
  permit_ids: string[];
  quotas: Quotas;
}

export interface PydanticErrorDetail {
  loc: string[];
  msg: string;
  type: string;
}

export interface PydanticError {
  detail: PydanticErrorDetail[];
}

export interface RegisterData {
  email: string;
  password: string;
  username: string;
  optin: boolean;
}

export interface GetPurchasesResponse {
  id: string;
  customer_id: string;
  product_name: ProductName | string; // This may include other Anaconda products, which `| string` is needed.
  price_id: string;
  quantity: number;
  user_id: string;
  org_id: null;
  partner_id: null;
  created_at: string;
  expires_at: string;
  is_active: boolean;
}

export interface Badge {
  id: string;
  name: string;
  description: string;
}

export interface UserBadge {
  user_id: string;
  badge: Badge;
  awarded_at: string;
}

export interface Social {
  twitter: string;
  github: string;
  website: string;
  linkedin: string;
}

export interface GetProfileResponse {
  id: string;
  email: string;
  username: string;
  avatar: string;
  name: string;
  bio: string;
  created_at: string;
  public: boolean;
  socials: Social;
}

export interface GetUserStatsResponse {
  projects_forked: number;
  projects_created: number;
}

export interface PutUserProfile {
  bio?: string;
  name?: string;
  username?: string;
  socials?: Social;
  public?: boolean;
}

export interface PsdcApiError {
  code?: string;
  data?: Record<string, any>;
  message: string;
  status: number;
}

export interface PostCreateSecretBody {
  name: string;
  value: string;
  description?: string;
}

export interface PutCreateSecretBody extends PostCreateSecretBody {}

export interface Secret {
  id: string;
  name: string;
  description: string;
  created_at: string; // e.g. 2023-10-19T14:58:30.288Z
  updated_at: string; // e.g. 2023-10-19T14:58:30.288Z
  user_id?: string;
  value?: string;
}

export type HttpMethods =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'PATCH'
  | 'HEAD'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE';

export interface PostCreateApiProxyBody {
  name: string;
  description?: string;
  url: string;
  methods: HttpMethods[];
  headers: Record<string, string>;
  timeout: number;
}

export interface PutCreateApiProxyBody extends PostCreateApiProxyBody {}

export interface ApiProxy {
  id: string;
  name: string;
  description: string;
  url: string;
  headers: Record<string, string>;
  timeout: number;
  methods: HttpMethods[];
  created_at: string; // e.g. 2023-10-19T14:58:30.288Z
  updated_at: string; // e.g. 2023-10-19T14:58:30.288Z
}

export interface ApiKey {
  id: string;
  name: string;
  token: string;
  expiry: number;
}

export interface Favorite {
  project_id: string;
  user_id: string;
  created_at: string;
}

export interface ListProjectsResponse {
  page: number;
  page_size: number;
  total_pages: number;
  total_count: number;
  previous_page: string | null;
  next_page: string | null;
  results: Project[];
}

export type ListProjectsScope = 'mine' | 'other' | 'trending' | 'featured' | 'favorites';
export type ListProjectsSortBy = 'created_at' | 'updated_at' | 'name';
export type ListProjectsSortOrder = 'asc' | 'desc';

export interface ListProjectsOptions {
  user?: string;
  scope?: ListProjectsScope;
  pageSize?: number;
  page?: number;
  q?: string;
  /** Defaults to `updated_at`. */
  sortBy?: ListProjectsSortBy;
  /** Defaults to `desc`. */
  sortOrder?: ListProjectsSortOrder;
}

export type ListProjectsOptionsSnakeCase = SnakeCaseKeys<ListProjectsOptions>;

export interface Collection {
  id: string;
  name: string;
  description: string;
  private: boolean;
  projects: string[];
  created_at: string;
  updated_at: string;
  user_id: string;
  username: string;
}

export interface ListCollectionProjectsOptions {
  /** Username or ID */
  user?: string;
  q?: string;
  /** Defaults to `updated_at`. */
  sortBy?: ListProjectsSortBy;
  /** Defaults to `desc`. */
  sortOrder?: ListProjectsSortOrder;
}

export type ListCollectionsSortBy = 'created_at' | 'updated_at' | 'name';
export type ListCollectionsSortOrder = 'asc' | 'desc';

export interface ListCollectionsOptions {
  /** Username or ID */
  user: string;
  q?: string;
  /** Defaults to `updated_at`. */
  sortBy?: ListCollectionsSortBy;
  /** Defaults to `desc`. */
  sortOrder?: ListCollectionsSortOrder;
}
